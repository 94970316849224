/* https://tailwindcomponents.com/component/nestable-dropdown-menu */
li>ul {
    transform: translatex(100%) scale(0)
  }
  
  li:hover>ul {
    transform: translatex(101%) scale(1)
  }
  
  li>button svg {
    transform: rotate(-90deg)
  }
  
  li:hover>button svg {
    transform: rotate(-270deg)
  }
  
  /* Below styles fake what can be achieved with the tailwind config
       you need to add the group-hover variant to scale and define your custom
       min width style.
         See https://codesandbox.io/s/tailwindcss-multilevel-dropdown-y91j7?file=/index.html
         for implementation with config file
    */
  .group:hover .group-hover\:scale-100 {
    transform: scale(1)
  }
  
  .group:hover .group-hover\:-rotate-180 {
    transform: rotate(180deg)
  }
  
  .scale-0 {
    transform: scale(0)
  }
  
  .min-w-32 {
    min-width: 8rem
  }
  
  .p-inputnumber-input {
    text-align: right;
  }
  

.zIndex9999 {
  z-index: 9999 !important;
}